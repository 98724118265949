import { graphql } from 'gatsby'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { darken } from 'polished'
import PropTypes from 'prop-types'

import { colors } from '../styles/colors'
import Footer from '../components/Footer'
import SiteNav from '../components/header/SiteNav'
import Wrapper from '../components/Wrapper'
import IndexLayout from '../layouts'
import {
  AuthorProfileImage,
  inner,
  outer,
  SiteHeader,
  SiteHeaderContent,
  SiteTitle,
  SiteMain,
  SocialLink,
} from '../styles/shared'
import SEO from '../components/header/SEO'
import Website from '../components/icons/website'
import Twitter from '../components/icons/twitter'

const AuthorBio = styled.h2`
  z-index: 10;
  flex-shrink: 0;
  margin: 5px 0 10px 0;
  max-width: 600px;
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;
  margin-top: 20px;
`

const AuthorProfileBioImage = css`
  z-index: 10;
  flex-shrink: 0;
  margin: 0 0 20px 0;
  width: 100px;
  height: 100px;
  box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 6px;
`

const Grid = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`

const Skill = styled.div`
  width: 150px;
  height: 100px;
  margin: 10px;
  display: flex;
`

const SkillTitle = styled.span`
  font-size: 1.5rem;
  line-height: 1.3em;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #fff;
  opacity: 0.8;
`

const DarkBackground = css`
  background: ${darken('0.05', colors.darkgrey)} no-repeat center center;
`

const Title = styled.h1`
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
`

const SkillLogo = css`
  z-index: 10;
  flex-shrink: 0;
  margin: 0 0 20px 0;
  width: 50px;
  height: 50px;
  box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 6px;
`

const SkillStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Button = styled.button`
  background-color: transparent;
  color: #26a6ed;
  &:hover {
    text-decoration: underline;
  }
`

const Email = ({ email }) => {
  const [showEmail, setShowEmail] = useState(false)

  return (
    <div>
      {showEmail ? (
        <a href={`mailto:${email}`}>{email}</a>
      ) : (
        <Button type="button" onClick={() => setShowEmail(true)}>
          Show email
        </Button>
      )}
    </div>
  )
}

const Author = ({ data, location }) => {
  const author = data.contentfulPerson
  const skills = data.allContentfulSkill.edges.map(edge => edge.node).sort((a, b) => b.value - a.value)

  return (
    <IndexLayout>
      {/* <Helmet>
        <html lang={config.lang} />
        <title>
          {author.name} - {config.title}
        </title>
        <meta name="description" content={author.shortBio.shortBio} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={`${author.name} - ${config.title}`} />
        <meta property="og:url" content={location.href} />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.facebook && <meta property="article:author" content={config.facebook} />}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${author.name} - ${config.title}`} />
        <meta name="twitter:url" content={location.href} />
        {config.twitter && <meta name="twitter:site" content={`@${config.twitter.split('https://twitter.com/')[1]}`} />}
        {config.twitter && (
          <meta name="twitter:creator" content={`@${config.twitter.split('https://twitter.com/')[1]}`} />
        )}
      </Helmet> */}
      <SEO />
      <Wrapper>
        <header
          className="no-cover"
          css={[outer, SiteHeader]}
          style={{
            backgroundImage: author.profile_image ? `url(${author.profile_image.childImageSharp.fluid.src})` : '',
          }}
        >
          <div css={inner}>
            <SiteNav isHome={false} />
            <SiteHeaderContent>
              <Img fluid={author.image.fluid} css={[AuthorProfileImage, AuthorProfileBioImage]} alt={author.name} />

              <SiteTitle>{author.name}</SiteTitle>
              {author.email && <Email email={author.email} />}
              {author.title && author.company && (
                <div style={{ marginTop: '10px' }}>
                  {author.title} @{' '}
                  {author.companyWebsite ? <a href={author.companyWebsite}>{author.company}</a> : author.company}
                </div>
              )}
              {author.location && <div>{author.location}</div>}
              {author.shortBio && <AuthorBio>{author.shortBio.shortBio}</AuthorBio>}
              {author.website && (
                <div>
                  <a
                    className="social-link-wb"
                    css={SocialLink}
                    href={author.website}
                    title="Website"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Website />
                  </a>
                </div>
              )}
              {author.twitter && (
                <a
                  className="social-link-tw"
                  css={SocialLink}
                  href={`https://twitter.com/${author.twitter}`}
                  title="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter />
                </a>
              )}
            </SiteHeaderContent>
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer, DarkBackground]}>
          <div css={inner}>
            <Title>Technologies</Title>
            <div css={Grid}>
              {skills.map(skill => (
                <Skill css={SkillStyle} key={skill.title.toLowerCase().replace(/\s/g, '')}>
                  <Img
                    imgStyle={{ backgroundColor: '#fff', objectFit: 'contain' }}
                    fluid={skill.logo.fluid}
                    css={[SkillLogo]}
                    alt={skill.title}
                  />
                  <SkillTitle>{skill.title}</SkillTitle>
                </Skill>
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  )
}

Author.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default Author

export const pageQuery = graphql`
  query($author: String) {
    contentfulPerson(name: { eq: $author }) {
      name
      title
      company
      github
      company
      email
      companyWebsite
      location
      shortBio {
        shortBio
        childMarkdownRemark {
          htmlAst
        }
      }
      image {
        fluid(maxWidth: 3200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulSkill {
      edges {
        node {
          title
          value
          logo {
            fluid(maxWidth: 150) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
